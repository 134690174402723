import React from 'react';

const ProjectButton = ({ imgSrc, label, link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center lg:w-full w-[300px] h-25 border border-customGreen text-customGreen font-manrope rounded-[10px] gap-4 px-5 py-4  hover:bg-customDarkerGreen hover:border-customDarkerGreen hover:text-customBlack transition-colors duration-300"
    >
      <img src={imgSrc} alt={`${label} Img`} className="h-20 w-20 rounded-[10px] object-contain" />
      <span className="text-left">{label}</span>
    </a>
  );
};

export default ProjectButton;
