import React from 'react';
import ProjectTemplate from './ProjectTemplate';

const Luto = () => {
  const mainImage = '/assets/img/luto-1.png'; // caminho da imagem principal
  const galleryImages = [
    '/assets/img/luto-2.png',
    '/assets/img/luto-3.png',
    '/assets/img/luto-4.png',
    '/assets/img/luto-5.png',
  ];
  
  return (
    <ProjectTemplate
      title="Como falar de luto com crianças?"
      mainImage={mainImage}
      description="Projeto de editorial para diagramação de uma cartilha para o projeto de extensão Luto: Vivências e Possibilidades. A cartilha possui 24 páginas."
      tools={["InDesign", "Illustrator"]}
      galleryImages={galleryImages}
    />
  );
};

export default Luto;
