import React from 'react';
import ProjectTemplate from './ProjectTemplate';

const GuiaTCU = () => {
  const mainImage = '/assets/img/ui-1.png'; // caminho da imagem principal
  const galleryImages = [
    '/assets/img/ui-2.png',
    '/assets/img/ui-3.png',
    '/assets/img/ui-4.png',
    '/assets/img/ui-5.png',
  ];
  
  return (
    <ProjectTemplate
      title="Insign (Prototipagem)"
      mainImage={mainImage}
      description="Protótipo para gerenciamento de empresa gamificado feito para disciplina de Design de Interfaces da UFPR."
      tools={["Figma", "Illustrator", "Blender"]}
      galleryImages={galleryImages}
    />
  );
};

export default GuiaTCU;
