import React from 'react';
import ProjectTemplate from './ProjectTemplate';

const DriKlProject = () => {
  const mainImage = '/assets/img/drikl1-1.png'; // caminho da imagem principal
  const galleryImages = [
    '/assets/img/drikl1-2.png',
    '/assets/img/drikl1-3.png',
    '/assets/img/drikl1-4.png',
    '/assets/img/drikl1-5.png',
    '/assets/img/drikl1-6.png',
  ];
  
  return (
    <ProjectTemplate
      title="Dri-KL1"
      mainImage={mainImage}
      description="Projeto freelance de editorial para diagramação de TCC de 100 páginas. Imagens de mock-up digital."
      tools={["InDesign"]}
      galleryImages={galleryImages}
      
    />

    
  );
};

export default DriKlProject;
