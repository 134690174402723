// src/pages/Trabalhos.js
import React from 'react';
import ProjectButton from './ProjectButton';
import { Link } from 'react-router-dom';
import ReturnButton from './ReturnButton';

const Editorial = () => {
  return (
    <div className="min-h-screen min-w-full bg-customBlack flex-col justify-start items-center gap-2.5 inline-flex text-customGreen px-[5%] py-[100px]">
      
      <ReturnButton /> {/* Botão de retorno */}
      
      {/* Título */}
      <h1 className="lg:text-[80px] text-[60px] font-jacquard12 lg:mb-0 mb-[15px]">Editorial</h1>

      <div className="space-y-6">
        <Link to="/drikl1">
          <ProjectButton
            imgSrc="/assets/img/drikl1.png"
            label="DRI-KL1"
          />
        </Link>

        <div className='space-y-6'></div>
        
        <Link to="/guiaTCU">
          <ProjectButton
            imgSrc="/assets/img/guiaTCU.png"
            label="Guia de Saúde - TCU"
          />
        </Link>

        <div className='space-y-6'></div>
        
        <Link to="/luto">
          <ProjectButton
            imgSrc="/assets/img/luto.png"
            label="Como falar de luto com crianças?"
          />
        </Link>
      </div>      
    </div>
  );
}

export default Editorial;
