import React from 'react';
import ProjectTemplate from './ProjectTemplate';

const GuiaTCU = () => {
  const mainImage = '/assets/img/mural-1.png'; // caminho da imagem principal
  const galleryImages = [
    '/assets/img/mural-2.png',
  ];
  
  return (
    <ProjectTemplate
      title="Murais (Ilustração)"
      mainImage={mainImage}
      description="Murais feitos para a Diretoria de Saúde e Secretaria Geral de Administração do TCU. Os murais foram ilustrados e serviram de apoio para campanhas de saúde e comunicação."
      tools={["Illustrator", "Photoshop"]}
      galleryImages={galleryImages}
    />
  );
};

export default GuiaTCU;
