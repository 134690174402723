// src/components/WorkButton.js
import React from 'react';

const WorkButton = ({ iconSrc, label, link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center justify-center w-full h-16 border border-customGreen text-customGreen font-manrope rounded-[10px] gap-2.5 px-5 py-4  hover:bg-customDarkerGreen hover:border-customDarkerGreen hover:text-customBlack transition-colors duration-300"
    >
      <img src={iconSrc} alt={`${label} Icon`} className="h-8 w-8" />
      <span>{label}</span>
    </a>
  );
};

export default WorkButton;
