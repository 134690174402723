import React from 'react';
import ReturnButton from './ReturnButton';

const About = () => {
  return (
    <div className="min-h-screen bg-customBlack flex-col justify-start items-center gap-2.5 inline-flex text-customGreen px-[5%] py-[100px]">
      

      <ReturnButton /> {/* Botão de retorno */}
      
      
      {/* Título */}
      <h1 className="lg:text-[80px] text-[60px] font-jacquard12">Sobre mim</h1>

      {/* Imagem e Texto */}
      <div className="flex-col lg:flex-row justify-start items-center gap-[45px] inline-flex lg:space-x-10 lg:pr-[500px] lg:pl-[500px]">
        {/* Imagem */}
        <img
          src="/assets/img/eu.png"
          alt="Imagem Minha"
          className="w-52 h-52 rounded-[20px] object-cover"
        />

        {/* Texto de Apresentação */}
        <div className="font-manrope lg:px-0 px-12">
          <p>
          Sou graduada em Design Gráfico pela Universidade Federal do Paraná e atualmente estudo Análise e Desenvolvimento de Sistemas na PUCPR. Tenho grande interesse em ambas as áreas e, no momento, estou experimentando caminhos que unam criatividade e tecnologia. Possuo experiência em identidade visual, mídias sociais, ebooks e layouts para slides.
          </p>
        </div>
      </div>

      {/* Botões de contato */}
      <div className="flex flex-wrap space-x-4 justify-center mt-10"> 
        <a  href="mailto:annastrijker@outlook.com"
            className="px-4 py-2 border border-customGreen text-customGreen font-manrope rounded-[15px] justify-center items-center gap-2.5 inline-flex mb-4">
          <img src="/assets/icons/email.svg" alt="Ícone de email" className="h-5 w-5" />
          <span>annastrijker@outlook</span>
        </a>
        <a href="https://www.linkedin.com/in/annastrijker/" 
           target="_blank" rel="noopener noreferrer"
           className="px-4 py-2 border border-customGreen text-customGreen font-manrope rounded-[15px] justify-center items-center gap-2.5 inline-flex mb-4">
          <img src="/assets/icons/linkedin.svg" alt="Ícone do LinkedIn" className="h-5 w-5" />
          <span>LinkedIn</span>
        </a>
        <a className="px-4 py-2 border border-customGreen text-customGreen font-manrope rounded-[15px] justify-center items-center gap-2.5 inline-flex mb-4">
          <img src="/assets/icons/pin.svg" alt="Ícone de localização" className="h-5 w-5" />
          <span>Curitiba</span>
        </a>
        <a className="px-4 py-2 border border-customGreen text-customGreen font-manrope rounded-[15px] justify-center items-center gap-2.5 inline-flex mb-4">
          <img src="/assets/icons/graduation.svg" alt="Ícone de graduação" className="h-5 w-5" />
          <span>UFPR/PUCPR</span>
        </a>
      </div>
    </div>
  );
}

export default About;
