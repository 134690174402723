import React from 'react';
import ProjectTemplate from './ProjectTemplate';

const GuiaTCU = () => {
  const mainImage = '/assets/img/guiaTCU-1.png'; // caminho da imagem principal
  const galleryImages = [
    '/assets/img/guiaTCU-2.png',
    '/assets/img/guiaTCU-3.png',
    '/assets/img/guiaTCU-4.png',
    '/assets/img/guiaTCU-5.png',
  ];
  
  return (
    <ProjectTemplate
      title="Guia de Saúde - TCU"
      mainImage={mainImage}
      description="Projeto de editorial para diagramação de uma cartilha para a Diretoria de Saúde do TCU. A cartilha possui 60 páginas."
      tools={["InDesign", "Illustrator"]}
      galleryImages={galleryImages}
    />
  );
};

export default GuiaTCU;
