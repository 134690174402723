// src/pages/Trabalhos.js
import React from 'react';
import { Link } from 'react-router-dom';
import WorkButton from './WorkButton';
import Editorial from './Editorial';
import ReturnButton from './ReturnButton';

const Projects = () => {
  return (
    <div className="min-h-screen min-w-full bg-customBlack flex-col justify-start items-center gap-2.5 inline-flex text-customGreen px-[5%] py-[100px]">
      
      <ReturnButton /> {/* Botão de retorno */}
      
      {/* Título */}
      <h1 className="lg:text-[80px] text-[60px] font-jacquard12 lg:mb-0 mb-[15px]">Projetos</h1>

      <div className="space-y-6">
        <Link to="/editorial">
          <WorkButton
            iconSrc="/assets/icons/book.svg"
            label="Editorial"
          />
        </Link>
        
        <div className="space-y-6"></div>

        <Link to="/slides">
          <WorkButton
            iconSrc="/assets/icons/pc.svg"
            label="Slides"
          />
        </Link>

        <div className="space-y-6"></div>
        
        <Link to ="/ui">
          <WorkButton
            iconSrc="/assets/icons/ui.svg"
            label="Prototipagem - UI"
          />
        </Link>

        <div className="space-y-6"></div>

        <Link to='/ilustracao'>
          <WorkButton
            iconSrc="/assets/icons/paint.svg"
            label="Ilustração"
          />
        </Link>
        
        
      </div>      
    </div>
  );
}

export default Projects;
