import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const ReturnButton = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Condicional para não mostrar o botão na Home ('/')
  if (location.pathname === '/') {
    return null;
  }

  return (
    <button
      onClick={() => navigate(-1)} // Retorna para a página anterior
      className="absolute top-8 left-8 px-3 py-1 border border-customGreen text-customGreen font-manrope rounded-[10px] hover:bg-customDarkerGreen hover:border-customDarkerGreen hover:text-customBlack transition-colors duration-300"
    >
      Voltar
    </button>
  );
};

export default ReturnButton;
