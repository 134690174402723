import React, { useState } from 'react';

const ImageWithModal = ({ src, alt, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      {/* A imagem com a classe passada */}
      <img
        src={src}
        alt={alt}
        className={`cursor-pointer ${className}`}
        onClick={openModal}
      />
      {isOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50"
          onClick={closeModal}
        >
          <div className="max-w-3xl p-4">
            <img src={src} alt={alt} className="w-full h-auto" />
          </div>
        </div>
      )}
    </>
  );
};

export default ImageWithModal;
