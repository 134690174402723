import React from 'react';
import ReturnButton from './ReturnButton';
import ImageWithModal from './ImageWithModal';

const ProjectTemplate = ({ title, mainImage, description, tools, galleryImages }) => {
  return (
    <div className="relative min-h-screen min-w-full bg-customBlack flex-col justify-start items-center gap-2.5 inline-flex text-customGreen lg:px-[5%] px-12 py-[100px]">
      
      <ReturnButton /> {/* Botão de retorno */}
      
      {/* Título do Projeto */}
      <h1 className="text-6xl font-jacquard12 text-center mb-10">{title}</h1>
      
      {/* Imagem principal do projeto */}
      <div className="flex justify-center mb-8 lg:w-[600px] w-[300px]">
        <ImageWithModal src={mainImage} alt={`${title} Image`} className="w-full h-auto rounded-lg"/>
      </div>

      <div className="text-left text-lg mb-1">
        <p>Ferramentas utilizadas:</p>
      </div>
      
      {/* Ferramentas usadas */}
      <div className="flex justify-left mb-5">
        {tools.map((tool, index) => (
          <span key={index} className="bg-customBlack text-customGreen px-4 py-2 rounded-[15px] border-customGreen border-[1px] mr-2">
            {tool}
          </span>
        ))}
      </div>

      {/* Descrição do Projeto */}
      <div className="text-center lg:text-lg text-[15px] mb-10 border-y-[1px] border-customGreen py-6">
        <p>{description}</p>
      </div>

      {/* Galeria de Imagens */}
      <div className="grid grid-cols-1 gap-6">
        {galleryImages.map((image, index) => (
          <div key={index} className="flex justify-center lg:w-[600px] w-[300px]">
            <ImageWithModal src={image} alt={`Project gallery ${index}`} className="w-full h-auto rounded-lg"/>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectTemplate;
