import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="min-h-screen bg-customBlack flex flex-col lg:flex-row justify-between p-10 lg:p-20">
      
      {/* Main content */}
      <div className="flex flex-col lg:items-start lg:justify-end items-center justify-center flex-grow">
        <h1 className="text-6xl lg:text-9xl text-customGreen font-jacquard12">
          Anna Strijker
        </h1>
      </div>

      {/* Footer Buttons */}
      <div className="flex flex-col lg:flex-row items-center justify-center lg:items-end space-y-4 lg:space-y-0 lg:space-x-4 mt-4 lg:mt-0">
        <Link to="/trabalhos">
          <button className="px-3 py-1 bg-customGreen text-customBlack font-manrope font-medium rounded-lg hover:bg-customDarkerGreen">
            Trabalhos
          </button>
        </Link>
        <Link to="/sobre">
          <button className="px-3 py-1 border border-customGreen text-customGreen font-manrope font-medium rounded-lg hover:bg-customDarkerGreen hover:border-customDarkerGreen hover:text-customBlack">
            Sobre mim
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Home;
