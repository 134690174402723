import React from 'react';
import ProjectTemplate from './ProjectTemplate';

const Slides = () => {
  const mainImage = '/assets/img/slide-03.png'; // caminho da imagem principal
  const galleryImages = [
    '/assets/img/slide-2.png',
    '/assets/img/slide-1.png',
  ];
  
  return (
    <ProjectTemplate
      title="Slides"
      mainImage={mainImage}
      description="Apresentações feitas para a Diretoria de Saúde do TCU. Enviadas para todos os servidores e tercerizados periodicamente."
      tools={["InDesign", "Illustrator"]}
      galleryImages={galleryImages}
    />
  );
};

export default Slides;
