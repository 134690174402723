import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';
import Editorial from './components/Editorial';
import DriKlProject from './components/DriKlProject';
import GuiaTCU from './components/GuiaTCU';
import Luto from './components/Luto';
import Slides from './components/Slides';
import UI from './components/UI';
import Ilustration from './components/Ilustration';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sobre" element={<About />} />
        <Route path="/trabalhos" element={<Projects />} />
        <Route path='/editorial' element={<Editorial/>} />
        <Route path='/drikl1' element={<DriKlProject/>} />
        <Route path='/guiaTCU' element={<GuiaTCU/>} />
        <Route path='/luto' element={<Luto/>} />
        <Route path='/slides' element={<Slides/>} />
        <Route path='/ui' element={<UI/>} />    
        <Route path='/ilustracao' element={<Ilustration/>} />  
      </Routes>
    </Router>
  );
}

export default App;
